import styled, { keyframes } from "styled-components";
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
`;

export const CloseBtn = styled.button`
  background-color: transparent;
  color: #000;
  border: none;
  display: flex;
  justify-content: center;
  word-spacing: 3px;
  font-size: 1.2rem;
  /* color: #ffffffe6; */
  font-weight: 100;
  position: absolute;
  top: 5%;
  right: 4%;
  margin-top: 60px;
  z-index: 1002;
  cursor: pointer;
  p {
    color: black;
  }
  &:hover {
    color: #353535;
    /* animation: ${rotate} 0.5s linear ; */
  }
  @media (max-width: 768px) {
    margin-top: 16px;
    font-size: 2rem;
    margin-right: 0px;
    margin-bottom: 1rem;
    padding: 8px;
    top: 20px;
  }
  @media (hover: none) {
    :hover {
      color: initial;
    }
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 10%;
  right: 10%;
  width: 80%;
  height: 100%;
  border-style: value;
  background-color: rgba(255, 255, 255, 0.98);
  display: flex;
  justify-content: center;
  overflow-y: initial !important;
  z-index: 1000;
  pointer-events: auto;

  @media (max-width: 768px) {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;
export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%, -50%);
  width: 66%;
  height: 70%;
  background-color: none;
  z-index: 10;
  margin-top: 5vh;
  border-radius: 0.5rem;
  height: 80vh;
  overflow-y: scroll;
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  pointer-events: auto;
  z-index: 1001;
  ::-webkit-scrollbar {
    opacity: 0;
  }

  @media (max-width: 768px) {
    margin-top: 0;
    padding-top: 8px;
    width: 86%;
    height: 60%;
    top: 55%;
  }
`;
export const Text = styled.div`
  margin-top: 1rem;
  padding-left: 0.5rem;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 2.5rem;
  padding-bottom: 1rem;

  p {
    font-size: 12px;
    line-height: 0.5rem;
    font-weight: 100;
    /* color: rgba(0, 0, 0, 1); */
    text-transform: none;
    font-style: normal;
    letter-spacing: 0px;
  }
  @media (max-width: 768px) {
    p {
      font-size: 0.5rem;
      line-height: 1rem;
    }
  }
`;
