import PopHomeView from "../MainView/MainHomeView";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { client } from "../../client";

const CACHE_TIMEOUT = 30000; 

function AppView() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const cleanUpData = useCallback((rawData) => {
    const cleanData = rawData.map((videodata) => {
      const { sys, fields } = videodata;
      const { id } = sys;
      const videoName = fields.videoName; //1
      const videoDirectorName = fields.directorName; //2
      const videoCoverImage = fields.videoCoverImage.fields.file.url; //3
      const videoForGif = fields.videoForGif.fields.file.url; //4
      const linkForVimeo = fields.linkForVimeo; //5
      const cssid = fields.cssid; //6
      const producer = fields.producer; //7
      const videoType = fields.videoType; //8
      const secuence = fields.secuence; //9
      const Gaffer = fields.gaffer; //10
      const PostProduction = fields.postProduction; //11
      const CC = fields.cc; //12
      const SD = fields.sd; //13
      const Agency = fields.agency; //14
      const Client = fields.client; //15
      const Year = fields.year; //16
      const Production = fields.production; //17
      const MusicSound = fields.musicSound; //18
      const VO = fields.vo; //19
      const ArtDepartment = fields.artDepartment; //20
      const DOP = fields.dop; //21
      const setDesign = fields.setDesign; //22

      const updatedData = {
        id,
        videoName,
        videoDirectorName,
        videoCoverImage,
        videoForGif,
        linkForVimeo,
        cssid,
        producer,
        videoType,
        secuence,
        Gaffer,
        PostProduction,
        CC,
        SD,
        Agency,
        Client,
        Year,
        Production,
        MusicSound,
        VO,
        ArtDepartment,
        DOP,
        setDesign,
      };
      return updatedData;
    });
    localStorage.setItem(
      "videos",
      JSON.stringify({ data: cleanData, timestamp: Date.now() })
    );
    setVideos(cleanData);
    setLoading(false);
  }, []);

  const memoizedData = useMemo(() => videos, [videos]);

  const getData = useCallback(async () => {
    try {
      const response = await client.getEntries({ content_type: "adVideo" });
      const responseData = response.items;
      if (responseData) {
        cleanUpData(responseData);
      } else {
        setVideos([]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [cleanUpData]);

  useEffect(() => {
    const cachedVideos = JSON.parse(localStorage.getItem("videos"));
    if (cachedVideos && cachedVideos.timestamp + CACHE_TIMEOUT > Date.now()) {
      setVideos(cachedVideos.data);
      setLoading(false);
    } else {
      getData();
    }
  }, [getData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <PopHomeView data={memoizedData} />
    </div>
  );
}

export default AppView;
