import styled from "styled-components";
import React, { lazy } from "react";

const Sads = lazy(() => import("./Viewer/ViewAds.jsx"));

const Ads = (props) => {
  return (
    <Container>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Sads />
      </React.Suspense>
    </Container>
  );
};

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 250px);
  overflow-x: hidden;
  display: block;
  margin-top: 73px;
  padding: 0 calc(3.5vw + 5px);

  &:after {
    /* ÷ background: url("/images/merlin_198051936_85fe1347-4a63-431b-aefd-ab8922f73215-superJumbo.jpg.webp") center center / cover */
    /* no-repeat fixed; */
    content: "";
    position: absolute;
    inset: 0px;
    opacity: 1;
    z-index: -1;
  }
`;

export default Ads;
